import { render, staticRenderFns } from "./SearchSidebar.vue?vue&type=template&id=e5165534&scoped=true&"
import script from "./SearchSidebar.vue?vue&type=script&lang=ts&"
export * from "./SearchSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./SearchSidebar.vue?vue&type=style&index=0&id=e5165534&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5165534",
  null
  
)

export default component.exports