import Vue from "vue";

import tippy, { Placement, sticky } from "tippy.js";
import LoadScript from "vue-plugin-load-script";
import VueProgressBar from "vue-progressbar";
import VueHead from "vue-head";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initI18n } from "./lib/i18n";
import notifications from "./lib/mixins/notifications";
import forms from "./lib/mixins/forms";
import analytics from "./lib/mixins/analytics";
import utils from "./lib/mixins/utils";
import clipboard from "@/lib/mixins/clipboard";
import wb from "./registerServiceWorker";
import { getFeatureData, setFeatureDataItem } from "./lib/util";
import OgorMenu from "@/components/Menu/Menu.vue";
import OgorMenuItem from "@/components/Menu/MenuItem.vue";
import { DirectiveBinding } from "vue/types/options";
import SafeArea from "./components/SafeArea.vue";
import FeatureFlag from "./components/FeatureFlag.vue";
import SvgIcon from "./components/SvgIcon.vue";
import RoutesMixin from "./lib/mixins/routes";
import { $dialog } from "./components/DialogManager.vue";

initI18n();
Vue.config.productionTip = false;
Vue.use(LoadScript as any);
Vue.use(VueHead);

Vue.prototype.$workbox = wb;
Vue.prototype.$dialog = $dialog

/**
 * Directive that hides the visibility of an element, instead of removing it
 * from the DOM
 */
Vue.directive("visible", function (el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

/**
 * Directive that allows calling a function when a component has updated.
 *
 * No arguments are passed to the calling function.
 */
Vue.directive("updated", {
  componentUpdated(el, binding) {
    binding.value();
  },
});

/**
 * Directive to auto-focus on element every time it is inserted,
 * or reinserted into the DOM.
 *
 * Does not automatically scroll to the focus event. This should
 * be handled separately as it might interfere with transitions.
 */
Vue.directive("focus", {
  bind(el) {
    if (!el.getAttribute("tabindex")) {
      // If the tabindex is not set the browser will
      // refuse to focus on the element
      el.setAttribute("tabindex", "-1");
    }
  },
  inserted(el, binding) {
    if (!binding || binding.value) {
      el.focus({ preventScroll: true });
    }
  },
  update: function (el, binding) {
    if (binding && binding.value && !binding.oldValue) {
      el.focus({ preventScroll: true });
    }
  },
});

/**
 * Directive to show different tooltips based on localstorage.
 * You must provide a name for the tooltip and its content.
 * Example usage: v-onboarding-tooltip:[name]='content'
 */
Vue.directive("onboardingTooltip", {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    const name = binding.arg;
    const maybeOpenTooltip = () => {
      const data = getFeatureData("onboardingTooltips");
      if (data[name]) return;
      const content = binding.value;
      tippy(el, {
        content,
        delay: 0,
        trigger: "manual",
        hideOnClick: true,
        sticky: binding.modifiers.sticky,
        plugins: [sticky],
        onHidden() {
          const tooltipTimeout = parseInt(el.dataset.tooltipTimeout);
          if (tooltipTimeout) {
            window.clearTimeout(tooltipTimeout);
          }
          setFeatureDataItem("onboardingTooltips", name, true);
        },
      });
      el._tippy.show();
    };
    const dataset = el.dataset;
    dataset.tooltipTimeout = '' + window.setTimeout(maybeOpenTooltip, 300);
  },
  unbind(el) {
    const dataset = el.dataset;
    const tooltipTimeout = parseInt(dataset.tooltipTimeout);
    if (tooltipTimeout) {
      window.clearTimeout(tooltipTimeout);
    }
    el._tippy?.hide();
  },
});

function createTippy(el: HTMLElement, binding: DirectiveBinding) {
  if(!binding.value) return;
  tippy(el, {
    content: binding.value,
    sticky: binding.modifiers.sticky,
    hideOnClick: !binding.modifiers.notDismissable,
    allowHTML: binding.modifiers.allowHTML,
    placement: (binding.arg || "top") as Placement,
    plugins: [sticky],
  });
}

Vue.directive("tooltip", {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    createTippy(el, binding);
  },
  update(el, binding) {
    el._tippy?.destroy();
    createTippy(el, binding);
  },
  unbind(el) {
    el._tippy?.destroy();
  },
});

const options = {
  color: "#3869FF",
  failedColor: "#DA4032",
  thickness: "0.5rem",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 1000,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};
Vue.use(VueProgressBar, options);

Vue.mixin(utils);
Vue.mixin(forms);
Vue.mixin(clipboard);
Vue.mixin(analytics);
Vue.mixin(notifications);
Vue.mixin(RoutesMixin);

Vue.component("OgorMenu", OgorMenu);
Vue.component("OgorMenuItem", OgorMenuItem);
Vue.component("SafeArea", SafeArea);
Vue.component("FeatureFlag", FeatureFlag);
Vue.component("SvgIcon", SvgIcon);

tippy.setDefaultProps({ zIndex: 9800, delay: [300, 0], duration: 200 });

new Vue({
  router,
  store,
  provide: {
    currentDialog: $dialog.current,
  },
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  render: (h) => h(App as any),
}).$mount("#app");
