/**
 * Handle i18n initialization and language change.
 */

import Vue from "vue";
import GetTextPlugin from "vue-gettext";
import EventBus from "./event-bus";

export type AppLanguage = 'en' | 'ro' | 'fr';

const defaultLanguage = "en";
// If this list changes the prerender list in the `vue.config.js` file needs to be
// updated as well.
const translations = { en: {}, ro: {}, fr: {} };
const translationLoaders = {
  en: () => import("../assets/translations/en.json"),
  ro: () => import("../assets/translations/ro.json"),
  fr: () => import("../assets/translations/fr.json"),
};

/**
 * Initialize the translations engine.
 */
export function initI18n() {
  Vue.use(GetTextPlugin, {
    availableLanguages: {
      ro: "Română",
      fr: "Français",
      en: "English",
    },
    defaultLanguage,
    translations,
    silent: true,
  });
}

const dateFormats = {
  /** @example 2021 */
  year: { year: "numeric" },

  /** @example sept. 2021 (ro) */
  month: { month: "short", year: "numeric" },
  
  /** @example 21 sept. (ro) */
  dateShort: { day: "numeric", month: "short" },
  
  /** @example 21 sept. 2021 (ro) */
  date: { day: "numeric", month: "short", year: "numeric" },

  /** @example 21 septembrie 2021 (ro) */
  dateLong: { dateStyle: "long" },

  /** @example 21 */
  day: { day: "numeric" },

  /** @example 16:04 */
  time: { hour: "numeric", minute: "numeric" },
} as const

type DateFormats = keyof typeof dateFormats

const numberFormats = {

  ha: { 
    style: "unit",
    unit: "hectare",
    unitDisplay: "short",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // trailingZeroDisplay: "stripIfInteger",
  } as Intl.NumberFormatOptions,

  "%": {
    style: "percent",
    notation: "compact",
    maximumFractionDigits: 1,
  } as Intl.NumberFormatOptions,

  lei: {
    style: "currency",
    currency: "RON",
    currencyDisplay: "narrowSymbol",
  } as Intl.NumberFormatOptions,

  "C": {
    style: "unit",
    unit: "celsius",
    unitDisplay: "short",
    maximumFractionDigits: 1,
    trailingZeroDisplay: "stripIfInteger",
  } as Intl.NumberFormatOptions,

} as const

type NumberFormats = keyof typeof numberFormats

/**
 * @todo Could add defaults to format
 */
export function format(value: number, format: NumberFormats | number): string
export function format(value: Date, format: DateFormats): string
export function format(value: any, format: string | number): string {
  const lang = window.currentLanguage || defaultLanguage

  if (value instanceof Date) {
    return value.toLocaleDateString(lang, dateFormats[format])
  }

  if (typeof value === "number") {
    if (typeof format === "number") {
      return value.toLocaleString(lang, {
        minimumFractionDigits: format,
        maximumFractionDigits: format,
      })
    }
    return value.toLocaleString(lang, numberFormats[format]).replace(" ", " ")
  }

  throw Error("No format available for " + typeof value + " and " + format)
}

declare module 'vue/types/vue' {
  interface Vue {
    format: typeof format;
  }
}

Vue.prototype.format = format

/**
 * Change the application current language. If the translations are not loaded
 * yet, they are first fetched from the server before loading.
 *
 * @param languageKey The two letter language code.
 * @param vm  The Vue instance
 * @param savePreference if set to true save the user pref in the local storage
 */
export async function setLanguage(languageKey: string, vm: Vue, savePreference = true) {
  if (
    !translations[languageKey] ||
    !Object.keys(translations[languageKey]).length
  ) {
    EventBus.$emit(EventBus.events.requestStarted);
    try {
      const languageResponse = await translationLoaders[languageKey]();
      translations[languageKey] = languageResponse[languageKey];
    } catch (e) {
      EventBus.$emit(EventBus.events.apiUnknownError);
    } finally {
      EventBus.$emit(EventBus.events.requestFinished);
    }
  }
  if (savePreference) {
    try {
      window.localStorage.setItem("language", languageKey);
    } catch (e) {
      // User doesn't seem to allow storage
    }
  }
  window.currentLanguage = languageKey;
  vm.$language.current = languageKey;
}
