import Vue, { Component } from "vue";
import VueRouter, { RawLocation } from "vue-router";
import { isMobile, isStandalone } from "@/lib/util";
import { $dialog } from "@/components/DialogManager.vue";

// Public views
const Landing = () =>
  import(/* webpackChunkName: "landing" */ "../views/Public/Landing.vue");
// Public auth views
const Authentication = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Authentication.vue");
const Legal = () =>
  import(/* webpackChunkName: "legal" */ "../views/Public/Legal.vue");
const Login = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Auth/Login.vue");
const ForgotPassword = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Auth/ForgotPassword.vue");
const ResetPassword = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Auth/ResetPassword.vue");
const AccountConfirm = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Auth/AccountConfirm.vue");
const OAuth = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Auth/OAuth.vue");
const EmailConfirmation = () =>
  import(
    /* webpackChunkName: "auth" */ "../views/Public/Auth/EmailConfirmation.vue"
  );
const Redirect = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Redirect.vue");
const NewAccount = () =>
  import(/* webpackChunkName: "auth" */ "../views/Public/Auth/NewAccount.vue");

// OGOR app
const CropOverview = () =>
  import(/* webpackChunkName: "ogorAppHome" */ "../views/App/CropOverview.vue");
const GridView = () =>
  import(/* webpackChunkName: "ogorAppHome" */ "../views/App/GridView.vue");
const MapView = () =>
  import(/* webpackChunkName: "ogorAppHome" */ "../views/App/MapView.vue");
const UserSettings = () =>
  import(
    /* webpackChunkName: "ogorAppUserSettings" */ "../views/App/UserSettings.vue"
  );
const ParcelHistory = () =>
  import(/* webpackChunkName: "ogorAppHistory" */ "../views/App/ParcelHistory.vue");
const Snapshot = () =>
  import(/* webpackChunkName: "ogorSnapshot" */ "../views/App/Snapshot.vue");

// Compare Views
const CompareParcels = () =>
  import(
    /* webpackChunkName: "ogorCompare" */ "../views/App/Compare/CompareParcels.vue"
  );
const CompareObservations = () =>
  import(
    /* webpackChunkName: "ogorCompare" */ "../views/App/Compare/CompareObservations.vue"
  );
const YieldPage = () =>
  import(/* webpackChunkName: "ogorYield" */ "../views/App/Yield.vue");

const YieldLanding = () =>
  import(/* webpackChunkName: "ogorYield" */ "../views/App/YieldForecast.vue");

// Manage farm
const ManageFarm = () =>
  import(/* webpackChunkName: "ogorManageFarm" */ "../views/App/ManageFarm.vue");
const ImportApiaTutorial = () =>
  import(
    /* webpackChunkName: "ogorManageFarm" */ "../components/Modals/Import/ImportApiaTutorial.vue"
  );
const MapPreview = () =>
  import(/* webpackChunkName: "ogorManageFarm" */ "../views/App/MapPreview.vue");

const MapDraw = () =>
  import(/* webpackChunkName: "ogorManageFarm" */ "../views/App/MapDraw.vue");

const AlcedoLanding = () =>
  import(/* webpackChunkName: "ogorAlcedoLanding" */ "../views/Public/AlcedoLanding.vue");

const MapAlcedoOrder = () =>
  import(/* webpackChunkName: "ogorMapAlceloOrder" */ "../views/App/MapAlcedoOrder.vue");

const MapAlcedoResults = () =>
  import(/* webpackChunkName: "MapAlcedoResults" */ "../views/App/MapAlcedoResults.vue");

const SoilMap = () =>
  import(/* webpackChunkName: "ogorSoilMap" */ "../views/App/SoilMapView.vue");

// Admin view
const UsersAdmin = () =>
  import(/* webpackChunkName: "ogorAdmin" */ "../views/Admin/Users.vue");

const ParcelDetails = () =>
  import(/* webpackChunkName: "ogorParcelDetails" */ "../views/App/ParcelDetails.vue");

const CropDetails = () =>
  import(/* webpackChunkName: "ogorCropDetails" */ "../views/App/CropDetails.vue");
Vue.use(VueRouter);

const YieldSeo = (app) => ({
  title: app.$gettext("OGOR Yield"),
  description: app.$gettext(
    "The maize forecast is based on the analysis of vegetation maps " +
      "(NDVI), historical and forecasted weather data and " +
      "culture-specific soil humidity reserves."
  ),
  image: "/yield/images/social.jpeg",
});

const routes = [
  // Public
  {
    path: "/",
    name: "landing",
    component: Landing,
    meta: {
      public: true,
      requireAuth: false,
      noStandalone: true,
    },
    alias: "/en",
  },
  {
    path: "/no-redirect",
    name: "landing-no-redirect",
    component: Landing,
    meta: {
      public: true,
      requireAuth: false,
      noStandalone: true,
      noRedirect: true,
    },
  },
  {
    path: "/fr",
    name: "landing-fr",
    component: Landing,
    meta: {
      public: true,
      requireAuth: false,
      lang: "fr",
      noStandalone: true,
    },
  },
  {
    path: "/ro",
    name: "landing-ro",
    component: Landing,
    meta: {
      public: true,
      requireAuth: false,
      lang: "ro",
      noStandalone: true,
    },
  },
  {
    path: "/demo-farm",
    name: "demo-farm",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
  },
  // Public auth
  {
    path: "/auth",
    component: Authentication,
    meta: { requireAuth: false, public: true },
    children: [
      {
        path: "login",
        component: Login,
        name: "login",
        meta: { requireAuth: false, public: true },
      },
      {
        path: "new-account",
        component: NewAccount,
        name: "new-account",
        meta: { requireAuth: false, public: true },
      },
      {
        path: "forgot-password",
        component: ForgotPassword,
        name: "forgot-password",
        meta: { requireAuth: false, public: true },
      },
      {
        path: "reset-password",
        component: ResetPassword,
        name: "reset-password",
        meta: { requireAuth: false, public: true, noRedirect: true },
      },
      {
        path: "facebook",
        component: OAuth,
        name: "oauth",
        meta: { requireAuth: false, public: true },
      },
      {
        path: "account-confirm",
        component: AccountConfirm,
        name: "account-confirm",
        meta: {
          requireAuth: false,
          public: true,
          noRedirect: true,
        },
      },
      {
        path: "email-confirmation",
        component: EmailConfirmation,
        name: "email-confirmation",
        meta: {
          requireAuth: false,
          public: true,
          noRedirect: true,
        },
      },
    ],
  },
  // Legal Page
  {
    path: "/legal/:selectedTerm?",
    name: "legal",
    component: Legal,
    props: true,
    meta: { requireAuth: false, public: true, noRedirect: true },
  },
  {
    path: "/redirect",
    component: Redirect,
    name: "redirect",
    meta: { requireAuth: false, public: true },
  },
  // Manage farm
  // TEMPORARY
  {
    path: "/manage/farm/:farmId/season/:seasonId?/choose-import-type",
    component: ManageFarm,
    name: "choose-import-type",
    meta: {
      mobileSupport: true,
    },
  },
  {
    path: "/manage/farm/:farmId",
    name: "manage-farm",
    component: ManageFarm,
    meta: {
      requireAuth: true,
      requireFullAccess: true,
      showSignupPopup: false,
      mobileSupport: true,
      displayName: (app) => app.$gettext("Manage farm"),
    },
    children: [
      // {
      //   path: "season/:seasonId?/choose-import-type",
      //   component: ImportChoiceModal,
      //   name: "choose-import-type",
      //   meta: {
      //     mobileSupport: true,
      //   },
      // },
      // {
      //   path: "season/:seasonId?/apia-auto-import",
      //   component: ImportApiaModal,
      //   name: "apia-auto-import",
      //   meta: {
      //     mobileSupport: true,
      //   },
      // },

      {
        path: "season/:seasonId?/apia-tutorial",
        component: ImportApiaTutorial,
        name: "apia-tutorial",
        meta: {
          mobileSupport: true,
        },
      },
      // {
      //   path: "season/:seasonId?/choose-file-type",
      //   component: ImportChooseFormatModal,
      //   name: "choose-file-type",
      //   meta: {
      //     mobileSupport: true,
      //   },
      // },
      // {
      //   path: "season/:seasonId?/import-file/:fileType",
      //   component: ImportFileModal,
      //   name: "import-file",
      //   props: true,
      //   meta: {
      //     mobileSupport: true,
      //   },
      // },
      // {
      //   path: "season/:seasonId?/status/:status/file/:batchFileId?",
      //   component: ImportStatusModal,
      //   name: "import-status",
      //   props: true,
      //   meta: {
      //     mobileSupport: true,
      //   },
      // },
    ],
  },
  // Crop overview (current home page)
  {
    path: "/farm/:farmId?",
    name: "crop-overview",
    component: CropOverview,
    meta: {
      requireAuth: false,
      displayName: (app) => app.$gettext("Crops"),
    },
  },
  {
    path: "/farm/:farmId/season/:seasonId/date/:date/",
    name: "crop-overview-date-selected",
    component: CropOverview,
    meta: {
      requireAuth: false,
      displayName: (app) => app.$gettext("Crops"),
    },
  },
  // Farm grid View
  {
    path: "/grid/:farmId?",
    name: "grid",
    component: GridView,
    meta: {
      requireAuth: false,
      // displayName: (app) => app.$gettext("Grid"),
    },
  },
  {
    path: "/grid/:farmId/season/:seasonId/date/:date/offset/:offset?",
    name: "grid-date-selected",
    component: GridView,
    meta: {
      requireAuth: false,
      // displayName: (app) => app.$gettext("Grid"),
    },
  },
  {
    path: "/map/:farmId/draw/:seasonId",
    name: "map-draw",
    component: MapDraw,
    props: true,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },
  {
    path: "/map/:farmId/soil/:seasonId",
    name: "soil-map",
    component: SoilMap,
    props: true,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },
  {
    path: "/map/:farmId/soil/:seasonId/parcel/:parcelId",
    name: "soil-map-parcel-selected",
    component: SoilMap,
    props: true,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },
  // Farm map view
  {
    path: "/map/:farmId/file/:batchFileId",
    name: "map-preview",
    component: MapPreview,
    props: true,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
  },
  {
    path: "/map/:farmId?",
    name: "map",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
  },
  {
    path: "/map/:farmId/season/:seasonId/",
    name: "map-season-selected",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
  },
  {
    path: "/map/:farmId/season/:seasonId/date/:date",
    name: "map-date-selected",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
  },
  {
    path: "/map/:farmId/season/:seasonId/parcel/:parcelId/details/:selectedTab?",
    name: "map-parcel-selected",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
    alias: ["/map/:farmId/season/:seasonId/parcel/:parcelId"],
  },
  {
    path: "/map/:farmId/season/:seasonId/date/:date/parcel/:parcelId/details/:selectedTab?",
    name: "map-observation-details",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
    alias: ["/map/:farmId/season/:seasonId/date/:date/parcel/:parcelId"],
  },
  {
    path: "/map/:farmId/season/:seasonId/parcel/:parcelId/details/:selectedTab?",
    name: "map-shared-parcel",
    component: MapView,
    meta: {
      requireAuth: false,
      mobileSupport: true,
    },
    alias: ["/map/:farmId/season/:seasonId/parcel/:parcelId/"],
  },
  {
    path: "/snapshot/:farmId/season/:seasonId/date/:date/parcel/:parcelId",
    name: "snapshot",
    component: Snapshot,
    meta: { requireAuth: false, showSignupPopup: true },
  },
  {
    path: "/farm/:farmId/season/:seasonId/parcel/:parcelId/history",
    name: "parcel-history",
    component: ParcelHistory,
    meta: { requireAuth: false, showSignupPopup: true },
  },
  {
    path: "/farm/:farmId/season/:seasonId/parcel/:parcelId/interval/:intervalId/history",
    name: "parcel-history-crop-interval",
    component: ParcelHistory,
    meta: { requireAuth: false, showSignupPopup: true },
  },
  // Yield
  {
    path: "/yield/**",
    redirect: "/forecast/",
  },
  {
    path: "/forecast/",
    name: "yield-landing",
    alias: ["/prognoza/", "/rendement/"],
    component: YieldLanding,
    meta: {
      noRedirect: true,
      public: true,
      requireAuth: false,
      mobileSupport: true,
      seo: YieldSeo,
    },
  },
  {
    path: "/forecast/:dataType/",
    name: "yield",
    alias: ["/prognoza/:dataType/", "/rendement/:dataType/"],
    component: YieldPage,
    meta: { noRedirect: true, public: true, requireAuth: false, mobileSupport: true, seo: YieldSeo },
  },
  {
    path: "/forecast/:dataType/county/:county",
    name: "yield-county",
    alias: [
      "/prognoza/:dataType/county/:county",
      "/rendement/:dataType/county/:county",
    ],
    component: YieldPage,
    meta: { noRedirect: true, public: true, requireAuth: false, mobileSupport: true, seo: YieldSeo },
  },
  {
    path: "/forecast/:dataType/county/:county/crop/:crop",
    name: "yield-crop",
    alias: [
      "/prognoza/:dataType/county/:county/crop/:crop",
      "/rendement/:dataType/county/:county/crop/:crop",
    ],
    component: YieldPage,
    meta: { noRedirect: true, public: true, requireAuth: false, mobileSupport: true, seo: YieldSeo },
  },
  // {
  //   path: "/yield/:dataType/county/:county/details",
  //   name: "yield-county-details",
  //   component: YieldPage,
  //   meta: { requireAuth: false, mobileSupport: true },
  // },
  {
    path: "/forecast/:dataType/county/:county/crop/:crop/uat/:uat",
    name: "yield-uat-selected",
    alias: [
      "/forecast/:dataType/county/:county/uat/:uat",
      "/prognoza/:dataType/county/:county/uat/:uat",
      "/prognoza/:dataType/county/:county/crop/:crop/uat/:uat",
      "/rendement/:dataType/county/:county/crop/:crop/uat/:uat",
      "/rendement/:dataType/county/:county/uat/:uat",
    ],
    component: YieldPage,
    meta: { noRedirect: true, public: true, requireAuth: false, mobileSupport: true },
  },
  // {
  //   path: "/yield/:dataType/county/:county/uat/:uat/details",
  //   name: "yield-uat-details",
  //   component: YieldPage,
  //   meta: { requireAuth: false, mobileSupport: true },
  // },
  // User settings
  {
    path: "/user-settings",
    name: "user-settings",
    component: UserSettings,
    meta: {
      requireAuth: true,
      displayName: (app) => app.$gettext("Your account"),
      mobileSupport: true,
    },
  },
  // Compare
  {
    path: "/farm/:farmId/season/:seasonId/compare",
    name: "compare-parcels",
    component: CompareParcels,
    meta: { requireAuth: false, showSignupPopup: true },
  },
  {
    path: "/farm/:farmId/season/:seasonId/parcel/:parcelId/compare",
    name: "compare-observations",
    component: CompareObservations,
    meta: { requireAuth: false, showSignupPopup: true },
  },
  {
    path: "/myalcedosoil",
    name: "alcedo-landing",
    component: AlcedoLanding,
    props: true,
    meta: {
      requireAuth: false,
      public: true,
      noRedirect: true,
      mobileSupport: true,
    },
  },
  {
    path: "/myalcedosoil/:farmId",
    name: "alcedo-order",
    component: MapAlcedoOrder,
    props: true,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },
  {
    path: "/myalcedosoil/order/:orderId",
    name: "alcedo-results",
    component: MapAlcedoResults,
    props: true,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },
  // Admin
  {
    path: "/staff/users",
    name: "staff-users",
    component: UsersAdmin,
    meta: {
      requireAuth: true,
      requireStaff: true,
      mobileSupport: true,
      displayName: (app) => app.$gettext("Users"),
    },
  },
  {
    path: `/farm/:farmId/season/:seasonId/parcel/:parcelId/parcel_details/:date?`,
    name: "parcel-details",
    component: ParcelDetails,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },


  // Modals
  {
    path: "./crop-plan-update-target",
    name: "CropPlanUpdateTarget",
    meta: {
      title: "Target yield",
    },
    component: () => import("@/components/Plan/CropPlanUpdateTarget.vue"),
    props: true,
  },
  {
    path: "./crop-plan-close",
    name: "CropPlanClose",
    meta: {
      title: "Close worksheet",
    },
    component: () => import("@/components/Plan/CropPlanClose.vue"),
    props: true,
  },
  {
    path: "./activity-close",
    name: "ActivityClose",
    meta: {
      title: "Close activity",
    },
    component: () => import('@/components/Plan/ActivityClose.vue'),
    props: true,
  },
  {
    path: "./activity-update-cost",
    name: "ActivityUpdateCost",
    meta: {
      title: "Activity cost",
    },
    component: () => import('@/components/Plan/ActivityUpdateCost.vue'),
    props: true,
  },
  {
    path: "./activity-update-interval",
    name: "ActivityUpdateInterval",
    meta: {
      title: "Execution period",
    },
    component: () => import('@/components/Plan/ActivityUpdateInterval.vue'),
    props: true,
  },
  {
    path: "/farm/:farmId/season/:seasonId/crop/:cropId/crop_details/:date?",
    name: "crop-details",
    component: CropDetails,
    meta: {
      requireAuth: true,
      mobileSupport: true,
    },
  },
];

const _push = VueRouter.prototype.push
const _replace = VueRouter.prototype.replace

declare module "vue-router" {
  interface Location {
    as?: "modal"
  }
  interface RouteMeta {
    title?: string
  }
}

/** @todo do the same with replace() */
VueRouter.prototype.push = function(location: RawLocation) {
  if (typeof location === "object" && location.as) {
    if (location.as === "modal") {

      const match = this.match(location)
      const component = match.matched[0].components.default as Component
      const props = {
        ...location.params,
        meta: match.meta,
      }
      $dialog.showModal(component, props)
      return
    }
  }
  return _push.apply(this, arguments)
}

/**
 * Like VueRouter replace function, but ignored duplicate
 * navigations.
 */
VueRouter.prototype.replaceIgnore = function (location) {
  return this.replace(location).catch((reason) => {
    if (!reason) throw reason;
    if (
      reason.name !== "NavigationDuplicated" &&
      !reason.message.startsWith("Avoided redundant navigation")
    ) {
      throw reason;
    }
  });
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (isStandalone() && to.meta.noStandalone) {
    next({ name: "redirect" });
  } else if (isMobile() && !to.meta.public && !to.meta.mobileSupport) {
    next({ name: "redirect" });
  } else {
    next();
  }
});

export default router;
