import { apiCall } from "@/lib/api";
import { LRUCache } from "@/lib/LRUCache";
import { CropInterval, BECropInterval } from "@/lib/types/CropInterval";
import { Farm } from "@/lib/types/Farm";
import { Paginated } from "@/lib/types/Paginated";
import { BEParcel, Parcel } from "@/lib/types/Parcel";
import { Season } from "@/lib/types/Season";
import { groupBy, maybeParseJSON } from "@/lib/util";
import { ActionContext, ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { getCropInterval } from "../../lib/util";
import { ParcelSoil } from "@/lib/types/ParcelSoil";

export type ParcelHistory = Record<Season["id"], Parcel>;
export type ParcelOverlap = Parcel[];

export interface ParcelsState {
  selectedParcel: Parcel | null;
  selectedParcelId: Parcel["id"];
  selectedParcelCropIntervalId: CropInterval["id"];
  selectedParcelOverlap: ParcelOverlap;
  selectedParcelHistory: ParcelHistory;
  parcelOverlapCache: LRUCache<ParcelOverlap>;
  parcelHistoryCache: LRUCache<ParcelHistory>;
  parcelCache: LRUCache<Parcel>;
  recentParcels: LRUCache<Parcel>;
  parcelPlaceholderCache: LRUCache<Promise<Response>>,
  parcelsForCurrentSeason: Parcel[];
  parcelsForSeason: Record<Season["id"], Parcel[]>;
  parcelIndex: Record<Parcel["id"], Parcel>;
  parcelSoils: Record<Parcel["id"], ParcelSoil[]>;
  soilMode: 'soil_type' | 'texture';
}

function initialState(): ParcelsState {
  return {
    selectedParcel: null,
    selectedParcelId: null,
    selectedParcelCropIntervalId: null,
    selectedParcelOverlap: [],
    selectedParcelHistory: {},
    parcelOverlapCache: new LRUCache(50),
    parcelHistoryCache: new LRUCache(50),
    parcelCache: new LRUCache(50),
    recentParcels: new LRUCache(5),
    parcelPlaceholderCache: new LRUCache(50),
    parcelsForCurrentSeason: [],
    parcelsForSeason: {},
    parcelIndex: {},
    parcelSoils: {},
    soilMode: 'soil_type',
  };
}

export function processParcel(context: Context | null, _parcel: BEParcel) {
  let parcel: Parcel = _parcel as any;
  // @ts-ignore
  parcel.localityId = _parcel.locality?.id || _parcel.localityId as any;
  parcel.locality = _parcel.locality?.name || _parcel.locality as any;
  parcel.reference = [parcel.siruta, parcel.physical_block, parcel.parcel_no, parcel.crop_no].join("-");
  parcel.name = `${_parcel.locality || ""} ${_parcel.physical_block || ""}-${_parcel.parcel_no || ""}`;
  parcel.name = parcel.name.trim();
  parcel.nickname = _parcel.display_name;
  parcel.uniqueKey = _parcel.id;
  parcel.bbox = maybeParseJSON(_parcel.bbox);
  // @ts-ignore
  parcel.crop_intervals = processCropIntervals(_parcel.crop_intervals);
  parcel.shape = maybeParseJSON(_parcel.shape);
  parcel.representative_point = maybeParseJSON(_parcel.representative_point);
  parcel.selected = false;
  parcel.season = _parcel.season || context?.rootGetters.currentSeasonId;
  parcel.farm = _parcel.farm || context?.rootGetters.currentFarmId;
  return parcel;
}

function processParcelList(context: Context, parcels: BEParcel[]) {
  return parcels.map((parcel) => processParcel(context, parcel));
}
function processCropIntervals(cropIntervals: BECropInterval[]): CropInterval[] {
  return cropIntervals.map((interval) => {
    return {
      ...interval,
      start_date: interval?.interval?.lower || null,
      end_date: interval?.interval?.upper || null,
      user_start_date: interval?.interval?.lower || null,
      user_end_date: interval?.interval?.upper || null,
    };
  });
}

const mutations = createMutations({
  clearParcels(state: ParcelsState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  addToRecentParcels(state, parcel: Parcel) {
    state.recentParcels.addItem(parcel.id, { ...parcel });
  },
  addParcelToCache(state, parcel: Parcel) {
    state.parcelCache.addItem(parcel.id, parcel);
  },
  addToParcelHistoryCache(state, parcelHistory: ParcelHistory) {
    Object.values(parcelHistory).forEach((parcel) => {
      state.parcelHistoryCache.addItem(parcel.id, parcelHistory);
      state.parcelCache.addItem(parcel.id, parcel);
    });
  },
  addToParcelOverlapCache(state, { parcelId, parcelOverlap }: { parcelId: Parcel["id"], parcelOverlap: ParcelOverlap }) {
    state.parcelOverlapCache.addItem(parcelId, parcelOverlap);
  },
  clearParcelOverlapCache(state) {
    state.parcelOverlapCache = new LRUCache();
  },
  addToParcelPlaceholderCache(state, { placeholderUrl, promise }: { placeholderUrl: string, promise: Promise<Response> }) {
    state.parcelPlaceholderCache.addItem(placeholderUrl, promise);
  },
  /**
   * Set the currently selected parcel.
   */
  setSelectedParcel(state, parcel: Parcel) {
    state.selectedParcel = parcel;
    state.selectedParcelId = parcel ? parcel.id : null;
  },
  /**
   * Set parcel history
   */
  setSelectedParcelHistory(state, parcelHistory: ParcelHistory) {
    state.selectedParcelHistory = parcelHistory;
  },
  /**
   * Set parcel overlap
   */
  setSelectedParcelOverlap(state, parcelOverlap: ParcelOverlap) {
    state.selectedParcelOverlap = parcelOverlap;
  },
  /**
   * Set parcels for current season
   */
  setParcelsForCurrentSeason(state, parcels: Parcel[]) {
    state.parcelsForCurrentSeason = parcels;
  },
  setParcelsForSeason(state, { seasonId, parcels }: { seasonId: Season["id"], parcels: Parcel[] }) {
    state.parcelsForSeason[seasonId] = parcels;
  },
  addParcelsToParcelIndex(state, parcels: Parcel[]) {
    const parcelIndex = { ...state.parcelIndex };
    const parcelIndexLength = Object.keys(parcelIndex).length;
    parcels.forEach((parcel, index) => {
      parcelIndex[parcel.id] = parcel;
      parcelIndex[parcel.id].index = parcelIndexLength + index;
    });
    state.parcelIndex = { ...parcelIndex };
  },
  setParcelSelected(state, { parcelId, selected }: { parcelId: Parcel["id"], selected: boolean }) {
    if (!state.parcelIndex[parcelId]) return;
    state.parcelIndex[parcelId].selected = selected;
  },
  toggleParcelSelected(state, parcelId: Parcel["id"]) {
    if (!state.parcelIndex[parcelId]) return;
    state.parcelIndex[parcelId].selected =
      !state.parcelIndex[parcelId].selected;
  },
  clearAllSelectedParcels(state) {
    state.parcelsForCurrentSeason.forEach(
      (parcel) => (parcel.selected = false)
    );
  },
  setSelectedParcelCropIntervalId(state, intervalId: CropInterval["id"]) {
    state.selectedParcelCropIntervalId = intervalId;
  },
  setParcelSoils(state, parcelSoils: ParcelsState['parcelSoils']) {
    state.parcelSoils = parcelSoils;
  },
  setSoilMode(state, soilMode: ParcelsState['soilMode']) {
    state.soilMode = soilMode;
  },
});

export type ParcelsMutations = typeof mutations;

export interface ParcelsGetters extends GetterMap {
  selectedParcel: Parcel;
  selectedParcelId: Parcel["id"];
  parcelCache: ParcelsState["parcelCache"];
  parcelHistoryCache: ParcelsState["parcelHistoryCache"];
  parcelOverlapCache: ParcelsState["parcelOverlapCache"];
  parcelPlaceholderCache: ParcelsState["parcelPlaceholderCache"];
  selectedParcelHistory: ParcelsState["selectedParcelHistory"];
  selectedParcelOverlap: Record<Season["id"], ParcelOverlap>;
  recentParcels: Parcel[];
  parcelsForCurrentSeason: Parcel[];
  parcelsForSeason: (seasonId: Season["id"]) => Parcel[];
  parcelIndex: ParcelsState["parcelIndex"];
  parcelById: (parcelId: Parcel["id"]) => Parcel;
  selectedParcels: Parcel[];
  selectedParcelsArea: number;
  selectedParcelsIds: Parcel["id"][];
  selectedParcelsIdsSet: Set<Parcel["id"]>;
  selectedParcelCropInterval: CropInterval | null;
  cropIntervalByDate: CropInterval | null;
  parcelSoils: ParcelsState['parcelSoils'];
  soilMode: ParcelsState['soilMode'];
}

const getters: GetterTree<ParcelsState, ParcelsGetters> = {
  selectedParcel(state) {
    return state.selectedParcel;
  },
  selectedParcelId(state) {
    return state.selectedParcelId;
  },
  parcelCache(state) {
    return state.parcelCache;
  },
  parcelHistoryCache(state) {
    return state.parcelHistoryCache;
  },
  parcelOverlapCache(state) {
    return state.parcelOverlapCache;
  },
  parcelPlaceholderCache(state) {
    return state.parcelPlaceholderCache;
  },
  parcelById(state, getters) {
    return (parcelId) => {
      return getters.parcelsForCurrentSeason.find(
        (parcel) => parcel.id == parcelId
      )
    }
  },
  selectedParcelHistory(state) {
    return state.selectedParcelHistory;
  },
  selectedParcelOverlap(state) {
    return groupBy(state.selectedParcelOverlap, "season");
  },
  recentParcels(state) {
    return state.recentParcels.values();
  },
  parcelsForCurrentSeason(state, getters, rootState, rootGetters) {
    if (rootGetters.currentFarm && rootGetters.currentFarm.parcelAccessOnly) {
      return rootGetters.currentFarm.sharedParcels;
    }
    return state.parcelsForCurrentSeason;
  },
  parcelsForSeason(state, getters, rootState, rootGetters) {
    return (seasonId: Season["id"]) => {
      return getters.parcelsForCurrentSeason.filter(
        (parcel) => parcel.season == seasonId
      );
    };
  },
  parcelIndex(state) {
    return state.parcelIndex;
  },
  selectedParcels(state, getters) {
    return getters.parcelsForCurrentSeason.filter(
      (parcel) => parcel.selected
    );
  },
  selectedParcelsArea(state, getters) {
    return getters.selectedParcels.reduce(
      (accumulator, parcel) => accumulator + parcel.hectares,
      0
    );
  },
  selectedParcelsIds(state, getters) {
    return getters.selectedParcels.map((parcel) => parcel.id);
  },
  selectedParcelsIdsSet(state, getters) {
    return new Set(getters.selectedParcelsIds);
  },
  selectedParcelCropInterval(state) {
    return state.selectedParcel?.crop_intervals.find(
      (interval) => interval.id == state.selectedParcelCropIntervalId
    );
  },
  cropIntervalByDate(state, getters, rootState, rootGetters) {
    return getCropInterval(
      state.selectedParcel?.crop_intervals,
      rootGetters.selectedDate,
      rootGetters.currentSeason
    );
  },
  parcelSoils(state) {
    return state.parcelSoils;
  },
  soilMode(state) {
    return state.soilMode;
  },
};

type Context = ActionContext<ParcelsState, ParcelsGetters>;

export interface ParcelsActions extends ActionMap {
  /**
  * Load overlapping parcels with the currently selected one. As the each parcel
  * is tied to a specific season, we are guessing what the history of the parcel
  * is by looking at the overlapping parcels, and assigning them to a specific
  * season.
  */
  getSelectedParcelHistory: (newParcel: Parcel) => Promise<void>;
  getParcelOverlap: (newParcel: Parcel) => Promise<ParcelOverlap>;
  getParcelHistory: (newParcel: Parcel) => Promise<ParcelHistory>;
  /**
   * Load the parcel details from the API and store the results.
   */
  setCurrentParcel: (parcelId: Parcel["id"]) => Promise<void>;
  /**
   * Get parcel details either from the cache or the API.
   */
  getParcelDetails: (parcelId: Parcel["id"]) => Promise<Parcel>;
  getParcelPlaceholder: (placeholderUrl: string) => Promise<string>;
  getParcelsForCurrentSeason: () => Promise<Parcel[]>;
  getParcelsForSeason: (seasonId: Season["id"]) => Promise<void>;
  getAllSharedParcels: () => Promise<void>;
  getSharedParcels: (farmId: Farm["id"]) => Promise<void>;
} 

const actions: ActionTree<ParcelsState, ParcelsGetters, ParcelsActions> = {
  async getSelectedParcelHistory(context, newParcel) {
    if (
      !context.rootGetters.currentFarmId ||
      !context.rootGetters.currentSeasonId ||
      !context.rootGetters.userHasAccessToFarm
    ) {
      return;
    }

    if (!newParcel) {
      context.commit("setSelectedParcelOverlap", []);
      context.commit("setSelectedParcelHistory", {});
      return;
    }
    context.commit(
      "setSelectedParcelOverlap",
      await context.dispatch("getParcelOverlap", newParcel)
    );
    context.commit(
      "setSelectedParcelHistory",
      await context.dispatch("getParcelHistory", newParcel)
    );
  },
  async getParcelOverlap(context, newParcel) {
    if (!context.rootGetters.currentFarmId || !context.rootGetters.currentSeasonId) {
      return;
    }
    let parcelOverlap = context.getters.parcelOverlapCache.getItem(
      newParcel.id
    );
    if (parcelOverlap) {
      return parcelOverlap;
    }

    parcelOverlap = await apiCall(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${context.rootGetters.currentSeasonId}/parcel/${newParcel.id}/parcel_overlap/`
    );
    // Include a copy of the current parcel
    parcelOverlap.push({
      ...newParcel,
      season: context.rootGetters.currentSeasonId,
    });

    // If there is no crop interval, add one with unspecified crop
    parcelOverlap.forEach((parcel) => {
      if (parcel.crop_intervals.length == 0) {
        parcel.crop_intervals = [
          {
            id: "null",
            crop_id: "null",
            start_date: null,
            end_date: null,
            user_start_date: null,
            user_end_date: null,
          },
        ];
      }
    });

    context.commit("addToParcelOverlapCache", {
      parcelId: newParcel.id,
      parcelOverlap,
    });
    return parcelOverlap;
  },
  async getParcelHistory(context, newParcel) {
    let parcelHistory = context.getters.parcelHistoryCache.getItem(
      newParcel.id
    );
    if (parcelHistory) {
      return parcelHistory;
    }

    const parcelOverlap = await context.dispatch(
      "getParcelOverlap",
      newParcel
    );
    if (!parcelOverlap) return;

    parcelHistory = {};

    // XXX For cases where we have multiple overlapping parcels we simply use the last in the list for that
    // XXX season, as we get it from the API.
    // XXX We may however want to display an indicator to the user at some point, hence this will need to
    // XXX change significantly.
    parcelOverlap.forEach((parcel) => {
      parcelHistory[parcel.season] = { ...parcel, uniqueKey: parcel.id };
    });
    // If any of the overlapping parcels have similar reference, prefer those
    parcelOverlap.forEach((parcel) => {
      if (
        !parcel.siruta ||
        !newParcel.siruta ||
        !parcel.locality ||
        !newParcel.locality
      )
        return;
      if (parcel.siruta !== newParcel.siruta && parcel.locality !== newParcel.locality) {
        return;
      }
      parcelHistory[parcel.season] = { ...parcel, uniqueKey: parcel.id };
    });
    // If any of the overlapping parcels match the reference of the
    // current parcel, prefer that over anything else.
    parcelOverlap.forEach((parcel) => {
      if (
        parcel.reference !== newParcel.reference &&
        parcel.locality !== newParcel.locality
      ) {
        return;
      }
      parcelHistory[parcel.season] = { ...parcel, uniqueKey: parcel.id };
    });
    parcelHistory[newParcel.season] = {
      ...newParcel,
      uniqueKey: newParcel.id,
    };

    context.commit("addToParcelHistoryCache", parcelHistory);
    return parcelHistory;
  },
  async setCurrentParcel(context, parcelId) {
    if (
      context.getters.selectedParcelId === parcelId ||
      !context.rootGetters.currentFarmId ||
      !context.rootGetters.currentSeasonId
    ) {
      return;
    }
    const newParcel = await context.dispatch("getParcelDetails", parcelId);
    context.commit("setSelectedParcel", newParcel);
    await context.dispatch("getSelectedParcelHistory", newParcel);
  },
  async getParcelDetails(context, parcelId) {
    if (
      !context.rootGetters.currentFarmId ||
      !context.rootGetters.currentSeasonId ||
      !parcelId
    ) {
      return;
    }
    let newParcel = context.getters.parcelCache.getItem(parcelId);
    if (!newParcel) {
      newParcel = await apiCall(
        "GET",
        `/farm/${context.rootGetters.currentFarmId}/season/${context.rootGetters.currentSeasonId}/parcel/${parcelId}/`
      );
      newParcel.localityId = Object.values(
        context.rootGetters.localitiesForCurrentSeason
      ).find((locality) => locality.name == newParcel.locality)?.id;
      context.commit("addParcelToCache", newParcel);
    }
    newParcel.uniqueKey = newParcel.id;
    return processParcel(context, newParcel as any);
  },
  async getParcelPlaceholder(context, placeholderUrl) {
    const cache = context.getters.parcelPlaceholderCache;
    let promise = cache.getItem(placeholderUrl);
    if (!promise) {
      promise = fetch(placeholderUrl, {
        method: "GET",
        redirect: "follow",
      });
      context.commit("addToParcelPlaceholderCache", {
        placeholderUrl,
        promise,
      });
    }
    const response = await promise;
    // TODO: Add an error placeholder for the placeholder
    if (!response.ok) return;

    return await response.clone().text();
  },
  async getParcelsForCurrentSeason(context) {
    if (!context.rootGetters.currentFarmId || !context.rootGetters.currentSeasonId)
      return;
    const response = await apiCall<BEParcel[]>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${context.rootGetters.currentSeasonId}/list_parcels/`
    );
    const parcels = processParcelList(context, response);
    context.commit("setParcelsForCurrentSeason", parcels);
    context.commit("addParcelsToParcelIndex", parcels);
    return parcels;
  },
  async getParcelsForSeason(context, seasonId) {
    if (!context.rootGetters.currentFarmId || !seasonId) return;

    const response = await apiCall<BEParcel[]>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${seasonId}/list_parcels/`
    );
    const parcels = processParcelList(context, response);
    context.commit("addParcelsToParcelIndex", parcels);
  },
  async getAllSharedParcels(context) {
    await Promise.all(
      context.rootGetters.sharedFarms
        .filter((farm) => farm.parcelAccessOnly)
        .map((farm) => context.dispatch("getSharedParcels", farm.id))
    );
  },
  async getSharedParcels(context, farmId) {
    // TODO: we have an arbitrary hardcoded limit of parcel here.
    // TODO: We could iterate until the backend has no more parcels here.
    const response = await apiCall<Paginated<BEParcel>>("GET", "/shared-parcels/", {
      limit: 5000,
      farm_id: farmId,
    });
    const parcels = processParcelList(context, response.results);
    context.commit("setSharedParcels", {
      farmId: farmId,
      sharedParcels: parcels,
    });
  },
};

export default {
  strict: true,
  state: initialState(),
  mutations,
  actions,
  getters,
} as Module<ParcelsState, ParcelsGetters, ParcelsActions>;
