/**
 * Miscellaneous utilities, mostly because JS doesn't have batteries included. Or even a battery
 * slot for that matter.
 */

import { Jsts } from "@/custom";
import { RootGetters } from "@/store";
import { Component, Vue } from "@/vue";
import VueI from "vue";
import { RecordPropsDefinition } from "vue/types/options";
import { ExtendedVue } from "vue/types/vue";
import { mapGetters } from "vuex";
import {
  earthRadius,
  RASTER_HISTOGRAMS,
  RASTER_HISTOGRAM_KEYS,
  PLACEHOLDER_COLOR,
  skippedReasons,
  thumbnailZoomLevel,
  useSentry,
  WATER_CAPACITY_DEPTH,
  WATER_CAPACITY_SCALE_VALUES,
} from "./constants";
import { AppLanguage } from "./i18n";
import { CropInterval } from "./types/CropInterval";
import { Farm } from "./types/Farm";
import { Coordinate, CoordinateObject, Polygon } from "./types/Geometry";
import { Parcel } from "./types/Parcel";
import { Raster, RasterKind } from "./types/Raster";
import { Season } from "./types/Season";
import { apiCall } from "./api";
import { Crop } from "./types/Crop";

/**
 * Check if the current view is mobile
 */
export function isMobile() {
  return window.innerWidth <= 768;
}

/**
 * Check if app is running standalone
 */
export function isStandalone() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    !!window.navigator.standalone ||
    document.referrer.includes("android-app://")
  );
}

export function copyObject<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

/**
 * Get the corresponding season for this date
 */
export function seasonForDate(seasons: Season[] | Record<string, Season>, date: Date) {
  if (!seasons || !date) return;
  if (!Array.isArray(seasons)) {
    seasons = Object.values(seasons);
  }
  return seasons.find((season) => {
    return dateBetween(date, season.start_date, season.end_date);
  });
}

/** Returns the seasons that include the certain date (normally at most 2), sorted descending by end date. */
export function seasonsForDate(seasons: Season[] | Record<string, Season>, date: Date) {
  if (!seasons || !date) return [];
  if (!Array.isArray(seasons)) {
    seasons = Object.values(seasons);
  }
  return seasons.filter((season) => {
    return dateBetween(date, season.start_date, season.end_date);
  }).sort((a, b) => b.end_date.getTime() - a.end_date.getTime());
}

/**
 * Get the first available date for this season from the
 * given list.
 */
export function firstAvailableDate(season: Season, availableDates: Date[]): Date {
  if (!season || !availableDates) return null;
  return availableDates.find((date) =>
    dateBetween(date, season.start_date, season.end_date)
  );
}

/**
 * Get the value for the cookie with the specified name.
 * Case sensitive.
 *
 * Bring your own milk!
 *
 * @param name {string}
 * @returns {string}
 */
export function getCookie(name: string): string {
  const cookie = {};
  document.cookie.split(";").forEach((el) => {
    const [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function downloadFile(blob: Blob, filename: string) {
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const windowURL = window.webkitURL != null ? window.webkitURL : window.URL;
    const blobURL = windowURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    windowURL.revokeObjectURL(blobURL);
  }
}

/**
 * Title case a string.
 */
export function titleCase(str: string) {
  if (str.length <= 0) return str;
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Get UNIX epoch time as an Integer.
 */
export const getEpochTime = () => Math.floor(Date.now() / 1000);

/**
 * Format a float into a string with the specified number of digits after the
 * decimal. Avoids -0 values.
 *
 */
export function formatFloat(num: number | string, digits: number): string {
  let parsedNum = parseFloat("" + num);

  let value = (parsedNum > 0 && parsedNum < 0.1 ? 0.1 : parsedNum).toFixed(digits);
  if (parseFloat(value) === 0) {
    value = (0).toFixed(digits);
  }
  return value;
}

export function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}

/**
 * Remove diacritics from the string.
 */
export function unaccent(str: string) {
  if (!str) return str;
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * Truncate a string in the middle with a separator
 * ```
 * Example:
 *  truncateStringInMiddle("String so big it cannot even fit on the screen");
 * Output:
 *  "String so b...e screen"
 * ```
 */
export function truncateStringInMiddle(
  str: string,
  numCharsFront = 11,
  numCharsBack = 8,
  separator = "..."
) {
  const len = str.length;

  if (len <= numCharsFront + separator.length + numCharsBack) {
    return str;
  }

  return (
    str.substring(0, numCharsFront) +
    separator +
    str.substring(len - numCharsBack)
  );
}

/**
 * Compare two strings
 */
export function stringCompare(string1: string, string2: string) {
  string1 = (string1 || "").toUpperCase();
  string2 = (string2 || "").toUpperCase();
  return string1.localeCompare(string2);
}

export function numberCompare(number1: number, number2: number) {
  return number1 - number2;
}

/**
 * Case insensitive check if string includes the search string.
 */
export function ciIncludes(str: string, searchString: string): boolean {
  if (!str) return false;
  return str.toLowerCase().includes(searchString.toLowerCase());
}

/**
 * Get today's date, excluding the time information.
 */
export function getToday(): Date {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

/**
 * Return difference between two dates in days
 */
export function differenceDays(date1: Date, date2: Date) {
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

/**
 * Return difference between now and a date in days
 */
export function differenceNowDays(date: Date) {
  return differenceDays(getToday(), date);
}

/**
 * Return a new Date that does not have any information about time.
 */
export function toDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Format a Date object to "YYYY-MM-DD"
 */
export function formatDate(date: Date): string {
  if (!date) return "";
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();

  if (day.length === 1) {
    day = "0" + day;
  }
  if (month.length === 1) {
    month = "0" + month;
  }

  return `${date.getFullYear()}-${month}-${day}`;
}

/**
 * Parse a date string from "YYYY-MM-DD" format
 */
export function parseDate(dateStr: string) {
  if (!dateStr) return;
  const parts = dateStr.split(" ")[0].split("-");
  return new Date(
    parseInt(parts[0]),
    parseInt(parts[1]) - 1,
    parseInt(parts[2])
  );
}

export function parseDay(day) {
  return {
    date: parseDate(day.date),
    value: day.value || 0.0
  }
}

export function parseQueryBool(value: string, defaultValue: boolean) {
  if (value === undefined || value === null) return defaultValue;
  return value.toString().toLowerCase() === "true";
}

/**
 * Check if a date is between two dates.
 */
export function dateBetween(date: Date, startDate: Date, endDate: Date) {
  if (!date) return false;
  return (!endDate || date <= endDate) && (!startDate || date >= startDate);
}

/**
 * Check if two dates are equal.
 */
export function dateEqual(date1: Date, date2: Date) {
  return !!date1 && !!date2 && date1.getTime() === date2.getTime();
}

export function dateDiff(date1: Date, date2: Date) {
  return date1.getTime() - date2.getTime();
}

const MINUTE_TO_SECONDS = 60;
const HOUR_TO_SECONDS = 60 * MINUTE_TO_SECONDS;

/**
 * Returns the given number of seconds in a (HH:)?MM:SS format.
 */
export function secondsToString(seconds: number) {
  let hh = "" + Math.floor(seconds / HOUR_TO_SECONDS);
  let mm = "" + Math.floor((seconds % HOUR_TO_SECONDS) / MINUTE_TO_SECONDS);
  let ss = "" + (seconds % HOUR_TO_SECONDS) % MINUTE_TO_SECONDS;
  if (hh.length == 1) hh = "0" + hh;
  if (mm.length == 1) mm = "0" + mm;
  if (ss.length == 1) ss = "0" + ss;
  let result = hh != "00" ? `${hh}:` : "";
  result += `${mm}:${ss}`;
  return result;
}

/**
 * Group dates by month.
 *
 * @param objectList {[Object]} list of objects to group
 * @param dateProperty {string|null} property name of the date
 * @param locale {string} locale to use for dates
 * @param getNewObject {function} function to use to get the new object in the
 *  final result list
 * @return {[{firstOfMonth, month, objects}]}
 */
export function groupByMonth<T>(
  objectList: T[],
  dateProperty: keyof T | null = null,
  locale: AppLanguage = "en",
  getNewObject: (obj: T) => T = null
) {
  // First group by month.
  const groupedDates: { [key: string]: { firstOfMonth: Date, objects: T[], month: string } } = {};
  objectList.forEach((obj) => {
    const dateObj: Date = dateProperty ? new Date(obj[dateProperty] as any) : new Date(obj as any);
    const firstOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
    const key = formatDate(firstOfMonth);

    if (!groupedDates[key]) {
      groupedDates[key] = {
        firstOfMonth,
        objects: [],
        month: "",
      };
    }
    groupedDates[key].objects.push(getNewObject ? getNewObject(obj) : obj);
  });
  const sortedGroups = Object.values(groupedDates).sort(
    (a, b) => b.firstOfMonth.getTime() - a.firstOfMonth.getTime()
  );
  let currentYear = null;
  sortedGroups.forEach((group) => {
    const groupYear = group.firstOfMonth.getFullYear();
    group.month = group.firstOfMonth.toLocaleDateString(locale, {
      month: "long",
      year: currentYear !== groupYear ? "numeric" : undefined,
    });
    group.objects.sort((a, b) => new Date(b[dateProperty] as any).getTime() - new Date(a[dateProperty] as any).getTime());
    currentYear = groupYear;
  });
  return sortedGroups;
}

export function groupBy<T>(collection: T[], key: keyof T | ((item: T) => any)) {
  const getValue = typeof key === "function" ? key : (item: T) => item[key];

  return collection.reduce((storage, item) => {
    const group = getValue(item);
    storage[group] = storage[group] || [];
    storage[group].push(item);
    return storage;
  }, {} as Record<string, T[]>);
}

export function indexArray<T>(arr: T[], getKey: (item: T) => string | number | symbol): { [key: string]: T } {
  return arr.reduce((acc, item) => ({...acc, [getKey(item)]: item }), {});
}

export function removeDuplicates<T>(arr: T[], getKey: (item: T) => any = (item: T) => item) {
  const dict = indexArray(arr, getKey);
  return Object.values(dict);
}

export function range(start: number, end: number) {
  return Array.from({ length: end - start }, (_, i) => i + start);
}

export function groupObjectsValueByKey(objects) {
  const result = {};
  objects.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      result[key] = result[key] || [];
      result[key].push(value);
    });
  });
  return result;
}

export function avg(...values: number[]) {
  return values.reduce((s, i) => s + i, 0) / values.length;
}

/**
 * Check if an objects has a property, returns true regardless of the
 * property value.
 */
export function hasProperty(obj: Object, propertyName: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, propertyName);
}

/**
 * Given an interval for a NDVI histogram, return the key
 * use to map values with colors and other details.
 *
 * @param interval {Array} array with start and end of the interval
 */
export function intervalToKey(interval: [number, number]) {
  if (interval.length !== 2) return;
  // Insurance.
  return interval
    .map((value) => parseFloat(value as any))
    .sort((a, b) => a - b)
    .map((value) => value.toFixed(2))
    .join(", ");
}

export function colorForRaster(raster: Raster) {
  if (!raster) return PLACEHOLDER_COLOR;

  if (raster.skipped === skippedReasons.OVER_MAX_COVER) {
    return "#CAD1D2";
  }

  return colorForMean(raster.index_mean, raster.kind);
}

export function colorForMean(value: number, kind: RasterKind): string {
  const key = RASTER_HISTOGRAM_KEYS[kind].find(({ interval }) => {
    if (interval[0] < value && value <= interval[1]) {
      return true;
    }
  });

  if (!key) return PLACEHOLDER_COLOR;
  return RASTER_HISTOGRAMS[kind][intervalToKey(key.interval)];
}

export function boundsFromBbox(googleMaps: typeof google.maps, geometry: Polygon) {
  const bbox = geometry.coordinates[0];
  return new googleMaps.LatLngBounds(
    { lng: bbox[0][0], lat: bbox[0][1] }, // SW
    { lng: bbox[2][0], lat: bbox[2][1] } // NE
  );
}

/**
 * Get GMap bounding box from GeoJSON bbox
 *
 * @param googleMaps Google Maps JS. Must include the
 *   optional geometry library.
 * @param parcel serialized parcel from API.
 */
export function boundsFromParcel(googleMaps: typeof google.maps, parcel: Parcel) {
  const geometry = parcel.bbox;
  return boundsFromBbox(googleMaps, geometry);
}

/**
 * Get GMap bounding box from GeoJSON bbox
 *
 * @param googleMaps Google Maps JS. Must include the
 *   optional geometry library.
 * @param farm {Object} serialized farm from API.
 */
export function boundsFromFarm(googleMaps: typeof google.maps, farm: Farm) {
  if (!farm || !farm.map_bbox || !farm.map_bbox.coordinates) return;
  const geometry = farm.map_bbox;
  return boundsFromBbox(googleMaps, geometry);
}

/**
 * Get a GeoJSON feature from LatLngBounds.
 */
export function geoJSONFromBounds(bounds: google.maps.LatLngBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return {
    type: "Polygon",
    coordinates: [
      [
        [sw.lng(), sw.lat()],
        [sw.lng(), ne.lat()],
        [ne.lng(), ne.lat()],
        [ne.lng(), sw.lat()],
        [sw.lng(), sw.lat()],
      ],
    ],
  };
}

/**
 * Add padding to a rectangle in geographical coordinates.
 * @param googleMaps Google Maps JS. Must include the
 *   optional geometry library.
 * @param bounds
 * @param scale Scale to increase the distance between SW and NE
 */
export function padBounds(googleMaps: typeof google.maps, bounds: google.maps.LatLngBounds, scale: number) {
  const NE = bounds.getNorthEast();
  const SW = bounds.getSouthWest();
  const actualDistance = googleMaps.geometry.spherical.computeDistanceBetween(
    SW,
    NE
  );
  const headingSWtoNE = googleMaps.geometry.spherical.computeHeading(SW, NE);
  const headingNEtoSW = googleMaps.geometry.spherical.computeHeading(NE, SW);

  const newNE = googleMaps.geometry.spherical.computeOffset(
    SW,
    actualDistance * scale,
    headingSWtoNE
  );
  const newSW = googleMaps.geometry.spherical.computeOffset(
    NE,
    actualDistance * scale,
    headingNEtoSW
  );
  return new googleMaps.LatLngBounds(newSW, newNE);
}

/**
 * Convert degrees to radians
 * @param degrees
 */
export function radians(degrees: number) {
  return degrees * (Math.PI / 180);
}

/**
 * Calculate crow distance between two geographical coordinates.
 *
 * @return  km
 */
export function distanceBetween(lat1: number, lng1: number, lat2: number, lng2: number) {
  lat1 = radians(lat1);
  lng1 = radians(lng1);
  lat2 = radians(lat2);
  lng2 = radians(lng2);
  const deltaLng = lng2 - lng1;
  const deltaLat = lat2 - lat1;

  // Haversine formula
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
}

/**
 * Get diagonal distance of the parcel's bbox in Km
 *
 * @param parcel
 */
export function parcelBBoxSize(parcel: Parcel) {
  if (
    !parcel ||
    !parcel.bbox ||
    !parcel.bbox.coordinates ||
    parcel.bbox.coordinates.length <= 0
  )
    return 0;
  const bbox = parcel.bbox.coordinates[0];
  return distanceBetween(bbox[0][1], bbox[0][0], bbox[2][1], bbox[2][0]);
}

/**
 * Get "optimal" magnification factor for this parcel.
 *
 * Sauce full of jank.
 */
export function getParcelVariableZoomLevel(
  parcel: Parcel,
  baseZoomLevel = thumbnailZoomLevel
) {
  const distance = parcelBBoxSize(parcel);
  if (!distance) return baseZoomLevel;
  if (distance < 0.35) return baseZoomLevel + 3;
  if (distance < 0.6) return baseZoomLevel + 2;
  if (distance < 1.1) return baseZoomLevel + 1;
  if (distance < 1.8) return baseZoomLevel;
  if (distance < 2.7) return baseZoomLevel - 1;
  return baseZoomLevel - 2;
}

/**
 * Get a random choice from an Array of possible values.
 */
export function randomChoice<T>(choices: T[]) {
  const index = Math.floor(Math.random() * choices.length);
  return choices[index];
}

/**
 * Zip two arrays.
 */
export function zip<T, U>(array1: T[], array2: U[]): [T, U][] {
  return array1.map((item, index) => {
    return [item, array2[index]];
  });
}

export function bboxFromPolygon(polygon: Polygon["coordinates"][0]): Polygon {
  let north = -90,
    south = 90,
    east = -180,
    west = 180;
  polygon.forEach((point) => {
    const [lat, lng] = point;
    if (lat > north) north = lat;
    if (lat < south) south = lat;
    if (lng > east) east = lng;
    if (lng < west) west = lng;
  });
  return {
    type: "Polygon",
    coordinates: [
      [
        [south, west],
        [north, west],
        [north, east],
        [south, east],
        [south, west],
      ],
    ],
  };
}

/**
 * Parse a string if needed, otherwise return the object as is.
 */
export function maybeParseJSON<T>(val: string): T {
  if (typeof val === "string") {
    return JSON.parse(val);
  }
  return val;
}

// Handle Sentry lazily.

let _Sentry;

export async function getSentry() {
  if (!_Sentry) {
    _Sentry = await import(
      /* webpackChunkName: "sentry" */
      "@sentry/browser"
    );
  }
  return _Sentry;
}

export async function logMessage(message: string) {
  if (useSentry) {
    (await getSentry()).captureMessage(message, "error");
  } else {
    console.error(message);
  }
}

async function _logError(error: unknown) {
  if (useSentry) {
    (await getSentry()).captureException(error);
  } else {
    console.error(error, { ...error as any });
  }
}

export function logError(error: unknown) {
  _logError(error).catch(() => console.log(error));
}

/**
 * Get feature data from local storage.
 * @returns An object containing the feature data.
 */
export function getFeatureData<T extends Object = any>(featureName: string): Partial<T> {
  let data: Partial<T>;
  try {
    data = maybeParseJSON(window.localStorage.getItem(featureName)) || {};
  } catch (err) {
    data = {};
  }
  return data;
}

/**
 * Set a feature data item in the local storage.
 */
export function setFeatureDataItem(featureName: string, itemName: string, item: any) {
  let prevData = getFeatureData(featureName);
  try {
    let data = JSON.stringify({ ...prevData, [itemName]: item });
    window.localStorage.setItem(featureName, data);
  } catch (e) {
    // pass
  }
}

/**
 *
 */
export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });
}

/**
 *
 * @param imageBlob
 * @param maxShortSize max size of the short size of the photo
 * @param quality number between 0.1 and 1
 */
export async function resizeImage(
  imageBlob: Blob,
  maxShortSize = 1080,
  quality = 0.7
) {
  window.URL = window.URL || window.webkitURL;
  const blobURL = window.URL.createObjectURL(imageBlob);

  const image = new Image();
  image.src = blobURL;

  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement("canvas");
  let width = image.width;
  let height = image.height;
  const shortSize = Math.min(width, height);

  if (shortSize > maxShortSize) {
    const ratio = width / height;
    if (shortSize == width) {
      width = maxShortSize;
      height = width / ratio;
    } else {
      height = maxShortSize;
      width = height * ratio;
    }
  }

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL("image/jpeg", quality);
}

/**
 *
 * @param {Object} forecast
 */
export function parseOpenWeatherPrecipitation(forecast) {
  const rainOrShow = forecast?.rain || forecast?.snow;
  if (!rainOrShow) return 0;
  return rainOrShow["1h"] || rainOrShow["3h"] || rainOrShow;
}

const SECOND_TO_MS = 1000;
const MINUTE_TO_MS = 60 * SECOND_TO_MS;
const HOUR_TO_MS = 60 * MINUTE_TO_MS;
const DAY_TO_MS = 24 * HOUR_TO_MS;
const MONTH_TO_MS = 30 * DAY_TO_MS;
const YEAR_TO_MS = 365 * MONTH_TO_MS;

export function relativeTimeDiff(date1: Date, date2: Date) {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  let remainingDiff = diff;
  const years = Math.floor(remainingDiff / YEAR_TO_MS);
  remainingDiff -= years * YEAR_TO_MS;
  const months = Math.floor(remainingDiff / MONTH_TO_MS);
  remainingDiff -= months * MONTH_TO_MS;
  const days = Math.floor(remainingDiff / DAY_TO_MS);
  remainingDiff -= days * DAY_TO_MS;
  const hours = Math.floor(remainingDiff / HOUR_TO_MS);
  remainingDiff -= hours * HOUR_TO_MS;
  const minutes = Math.floor(remainingDiff / MINUTE_TO_MS);
  remainingDiff -= minutes * MINUTE_TO_MS;
  const seconds = Math.ceil(remainingDiff / SECOND_TO_MS);
  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function lngToX(lng: number) {
  return (lng + 180) * (256 / 360);
}

export function latToY(lat: number) {
  return (256 / 2) - (256 * Math.log(Math.tan((Math.PI / 4) + ((lat * Math.PI / 180) / 2))) / (2 * Math.PI));
}

export function latLngToPoint(latLng: google.maps.LatLng | google.maps.LatLngLiteral) {
  if(!(latLng instanceof google.maps.LatLng)) {
    latLng = new google.maps.LatLng(latLng);
  }
  return {
    x: lngToX(latLng.lng()),
    y: latToY(latLng.lat()),
  };
}

export function latLngToJstsCoordinate(latLng: google.maps.LatLng | google.maps.LatLngLiteral) {
  if(!(latLng instanceof google.maps.LatLng)) {
    latLng = new google.maps.LatLng(latLng);
  }
  return new jsts.geom.Coordinate(latLng.lng(), latLng.lat());
}

export function toJstsCoordinate([lng, lat]: Coordinate) {
  return new jsts.geom.Coordinate(lng, lat);
}

export function createJstsPolygon(jsts: Jsts, polygon: Coordinate[], holes?: Coordinate[][]) {
  const geometryFactory = new jsts.geom.GeometryFactory();
  const coordinates = polygon.map((point) => toJstsCoordinate(point));
  const shell = geometryFactory.createLinearRing(coordinates);
  const holesJsts = holes?.map((hole) => {
    const coordinates = hole.map((point) => toJstsCoordinate(point));
    return geometryFactory.createLinearRing(coordinates);
  }) || null;
  const jstsPolygon = geometryFactory.createPolygon(shell, holesJsts);
  return jstsPolygon;
}

export function parcelToJstsPolygon(
  jsts: Jsts,
  parcel: Parcel,
  polyData: (parcel: Parcel) => Object = (parcel) => ({ parcelId: parcel.id })
) {
  const jstsPolygon = createJstsPolygon(jsts, parcel.shape.coordinates[0], parcel.shape.coordinates.slice(1));
  jstsPolygon.setUserData(polyData(parcel));
  return jstsPolygon;
}

export function createJstsPoint(jsts: Jsts, { lat, lng }: CoordinateObject) {
  const geometryFactory = new jsts.geom.GeometryFactory();
  const coordinate = new jsts.geom.Coordinate(lng, lat);
  const point = geometryFactory.createPoint(coordinate);
  return point;
}

export function createJstsLine(jsts: Jsts, coordinates: CoordinateObject[]) {
  const geometryFactory = new jsts.geom.GeometryFactory();
  const jstsCoordinates = coordinates.map(coord => new jsts.geom.Coordinate(coord.lng, coord.lat));
  const line = geometryFactory.createLineString(jstsCoordinates);
  return line;
}

export function createJstsCirclePolygon(jsts: Jsts, { lat, lng }: CoordinateObject, bufferSize: number) {
  return createJstsPoint(jsts, { lat, lng }).buffer(bufferSize, null, null);
}

/** Get the hex color from an interval of 2 hex colors.
 * @param color1Hex
 * @param color2Hex
 * @param weight number between 0 and 1. smaller values mean closer to the first color
 */
export function pickHex(color1Hex: string, color2Hex: string, weight: number) {
  const w1 = 1 - weight;
  const w2 = weight;
  const color1 = hexToRgb(color1Hex);
  const color2 = hexToRgb(color2Hex);
  const [r, g, b] = [
    Math.round(color1.r * w1 + color2.r * w2),
    Math.round(color1.g * w1 + color2.g * w2),
    Math.round(color1.b * w1 + color2.b * w2),
  ];
  return rgbToHex(r, g, b);
}

export function rgbToHex(r: number, g: number, b: number) {
  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Format a number with commas.
 * @param number
 * @param digitNumber number of digits after the point
 * @param lang language to be formatted
 */
export function numberWithCommas(number: number | string, digitNumber: number = null, lang: string = "en") {
  const numberFloat = parseFloat("" + number);
  const numberString =
    digitNumber == null
      ? numberFloat.toString()
      : numberFloat.toFixed(digitNumber);
  const thousandSeparator = ["ro", "fr"].includes(lang) ? "." : ",";
  const subunitarySeparator = thousandSeparator == "." ? "," : ".";
  const parts = numberString.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return parts.join(subunitarySeparator);
}

export function almostEqual(x: number, y: number, eps = 1e-5) {
  return Math.abs(x - y) <= eps;
}

export function getCropInterval(crop_intervals: CropInterval[], date: Date | string, season: Season) {
  if (!crop_intervals || crop_intervals.length == 0) return null;
  if (!date) return crop_intervals[0];
  const dateObj = new Date(date);
  const interval =
    crop_intervals.find((interval) => {
      const startDate =
        interval.user_start_date || interval.start_date || season.start_date;
      const endDate =
        interval.user_end_date || interval.end_date || season.end_date;
      return (
        new Date(startDate).getTime() <= dateObj.getTime() &&
        dateObj.getTime() <= new Date(endDate).getTime()
      );
    }) || crop_intervals[0];
  interval.computed_start_date =
    interval.user_start_date || interval.start_date || formatDate(season.start_date);
  interval.computed_end_date =
    interval.user_end_date || interval.end_date || formatDate(season.end_date);
  return interval;
}

export function getCropIntervalCrop(crop_intervals: CropInterval[], date: Date | string, season: Season) {
  const interval = getCropInterval(crop_intervals, date, season);
  return interval?.crop?.id || interval?.crop_id || null;
}

export function getCropIntervalStr(interval: CropInterval, season: Season, language: string) {
  if (!interval) return "";
  const startDate = new Date(
    interval.user_start_date || interval.start_date || season.start_date
  );
  const endDate = new Date(
    interval.user_end_date || interval.end_date || season.end_date
  );

  const startDateStr =
    startDate.toLocaleDateString(language, {
      day: "numeric",
      month: "short",
      year: "2-digit",
    }) + (interval.user_start_date ? "" : "*");

  const endDateStr =
    endDate.toLocaleDateString(language, {
      day: "numeric",
      month: "short",
      year: "numeric",
    }) + (interval.user_end_date ? "" : "*");

  return `${startDateStr} – ${endDateStr}`;
}

export type NotEmptyArray<T> = [T, ...T[]];

export function gettersInstance<Key extends keyof RootGetters>(map: Key[]) {
  return Vue.extend<unknown, unknown, { readonly [K in Key]: RootGetters[K] }>({
    computed: {
      ...mapGetters(map)
    }
  });
}

export function propsInstance<Props extends Record<string, any>>(props: RecordPropsDefinition<Required<Props>>) {
  return Vue.extend<unknown, unknown, { $props: Props }, Required<Props>>({
    props,
  });
}

export function createGoogleMapsMarker<T>(googleMaps: typeof google.maps, opts: google.maps.CustomMarkerOptions<T>) {
  return new googleMaps.Marker(opts) as google.maps.CustomMarker<T>;
}

/**
 * Pad at the start with symbol up to a certain length.
 */
export function leadingSymbol(x: number | string, length: number, symbol: string = '0'): string {
  return ('' + x).padStart(length, symbol);
}

export function filterDict<K extends string | number | symbol, T>(dict: Record<K, T>, filterFn: (item: T) => boolean) {
  return Object.keys(dict).reduce((acc, key) => (
    filterFn(dict[key]) ? {...acc, [key]: dict[key] } : acc
  ), {} as Record<K, T>);
}

export function newCropInterval(cropId?: string): CropInterval {
  return {
    crop_id: cropId,
    start_date: null,
    end_date: null,
    user_start_date: null,
    user_end_date: null,
  };
}

type UnitType = { interval: [number, number], unit: string, transform: (value: number) => number | string };

export function transformValue(value: number, units: NotEmptyArray<UnitType>, absoluteIntervals = true) {
  const lookupValue = absoluteIntervals ? Math.abs(value) : value;
  const unit = units.find(({ interval }) => interval[0] <= lookupValue && lookupValue < interval[1]) || units[0];
  return unit.transform(value) + " " + unit.unit;
}

export function colorLegend() {
  return {
    "3": ["#FECB4F", "#000000"],
    "4": ["#CDCA45", "#000000"],
    "5": ["#9BBC29", "#000000"],
    "6": ["#6AA423", "#000000"],
    "7": ["#1E8B24", "#000000"],
    "8": ["#107116", "#ffffff"],
    "9": ["#085A26", "#ffffff"],
  };
}

export function getStyle(value: number) {
  if (value) {
    const key = clamp(parseInt("" + value), 3, 9).toFixed(0);
    const color = colorLegend[key];
    return {
      backgroundColor: color[0],
      color: color[1],
    };
  }
  return {};
}

export async function releaseHijack() {
  const data = {
    next: "/api/v1/auth/user/",
  };
  await apiCall("POST", "/hijack/release/", {}, data, "multipart/form-data");
  let oldPrefs = window.localStorage.getItem("_userPreferences");
  window.localStorage.setItem("userPreferences", oldPrefs);
  window.location.href = window.location.origin + "/";
}

export function getRasterMeanColor(kind: RasterKind, indexMean: number) {

  if (!kind || indexMean === undefined) return '#422112';

  const histogramValues = getHistogramValues(kind);
  return histogramValues.find(({ interval }) => indexMean >= interval[0] && indexMean <= interval[1])?.color || "white";
}

export function getHistogramValues(parcelRaster: string) {
  const histogram = buildHistogramForKind(parcelRaster);
  return RASTER_HISTOGRAM_KEYS[parcelRaster].map((interval) => histogram[intervalToKey(interval.interval)]);
}

export function buildHistogramForKind(rasterKind: string) {
  const histogramPerKind = {};
  RASTER_HISTOGRAM_KEYS[rasterKind].forEach((entry) => {
    const key = intervalToKey(entry.interval);
    histogramPerKind[key] = {
      ...entry,
      key,
      color: RASTER_HISTOGRAMS[rasterKind][key],
    };
  });
  return histogramPerKind;
}

export function filterParcelsByCrop(cropId: string, parcels: Parcel[]) {
  return parcels.filter((parcel) => parcel.crop_intervals.some((interval) => interval.crop_id === cropId));
}

export function getWaterCapacityAnalysis(date: Date, crop: Crop, type: string | null) {
  if (!crop) return null;

  const cropName = crop.name.en.toLowerCase();
  const analysis = {
    type: type || null,
    depth: null,
    scale: null,
  };

  // If the type is not provided, try to infer it from the crop name
  if (!analysis.type) {
    if (cropName.includes("maize")) analysis.type = "maize";
    // Add a space at the beginning to avoid matching buckwheat
    if (cropName.includes(" wheat")) analysis.type = "wheat";
    if (!analysis.type) return null;
  }

  analysis.depth = WATER_CAPACITY_DEPTH[analysis.type].find(
    (entry) =>
      date.getMonth() + 1 < entry.date.month || (date.getMonth() + 1 === entry.date.month && date.getDate() <= entry.date.day)
  );

  if (!analysis.depth) return null;

  analysis.scale = WATER_CAPACITY_SCALE_VALUES[analysis.type][analysis.depth.depth];

  return analysis;
}
